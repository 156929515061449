.curtain{
    position:fixed;
    left:0;
    top:0;
    width:100%;
    height:100%;
    background-color: #92929283;
}

.loader{
    position: fixed;
    left: 50%;
    top:50%;
    transform: translate(-50%, -50%);
    border: 10px solid #f3f3f3; 
    border-top: 10px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1.3s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
