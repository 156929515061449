
.login-container{
    position: absolute;
    left:50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 30%;
    min-width: 200px;
    border-radius: 25px;
    padding: 3em;
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 3px 3px 10px #0000004d;
    color: rgb(36, 36, 36);
    text-align: center;;
 }
  .login-container input{
    background: none;
    border: none;
    border-bottom: 1px solid #9e9e9e;
    border-radius: 0;
    outline: none;
    height: 2em;
    width: 100%;
    font-size: 1em;
    margin: 0 0 20px 0;
    padding: 0;
    box-shadow: none;
    box-sizing: content-box;
    transition: all 0.3s;
  }
 
 .login-container input:focus{
     border-bottom: 1px solid #26a69a;
     box-shadow: 0 1px 0 0 #26a69a;
 }
 
  .login-container input::placeholder{
      color:#9e9e9e !important;
  }
  .login-container img{
     width: 70%;
     max-width: 200px;
     margin: 2em;
  }

  .login-container button[type="submit"]{
    background-color:#2f2cef ;
    outline: none;
    border: none;
    border-radius: 10px;
    color: white;
    margin: 1em;
    font-size: 1.2em;
    padding:0.3em 1em;
    opacity: 0.9;
  }

  .login-container button[type="submit"]:hover{
      opacity: 1;
  }